import React from 'react';

import { section, gridDiv, topDiv, bottomDiv, title, subTitle, img } from './index.css'

export const OgOneColumnHero = () => {

  return (
    <>
      <section className={section}>
        <div className={gridDiv} >
          <div className={topDiv}>
            <picture>
              <source type="image/webp" srcSet="/images/firstview_desktop.webp" />
              <img className={img} src="/images/firstview_desktop.png" alt="社長を輩出することが持続可能な社会を作り出す。" />
            </picture>
          </div>
          <div className={bottomDiv}>
            <div className={title}>稼ぐ<br />
              遊ぶ<br />
              貢献する</div>
            <div className={subTitle}>稼ぐ・遊ぶ・貢献するをバランスよく楽しく<br className="sp" />こなすかっこいい経営者を目指す。
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
