import "src/components/organisms/WordPress/Navigation.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/Navigation.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE51U246bMBB9z1eMtFqpfXAEJIHE+9JKVX+jMnggbsGmtrNhU+XfKxsnAZakq/rF9zMzZ87M8ke8rX9XJxvBnwVAq4ywQkkKpeiQvywAclb8qrQ6SE4KVStN4em7H+7yRITk2FHY7XZuz4Vpa/ZGoayxcwduJlxoLHrYQtWHRrobVotKEmGxMRQKlBa1O96jqPaWQhxFz25vVUshcqsaSxuWR8Ht/vamZZwLWZFcWasaCmuNjXdddcSIk5AVhVxpjprkqntZnBfLS9jxvbBn7V4M+I3uHY3ukFSW5YcY+nkwVpRvpFDSorT9BcnRHhE/QlQagp0yrdVxhqqG6UpI0scU+68DNhLPxnwSj5q1FKRy8+PMXtLhKYyT4F9/TyqHkk4Nrx4YzpkRhkLSBzDw5V+eDPDXHv+WJQpPUZSlybcBRdvo1TN2E4PGmlnxiiOo1ENd/zyPLrOJnasaLtYGb7dDIHawapjVddqOhbobM1Rp4WXqZmKxaWtmkfSRmx4O4tILxTMeLbNNSMQdPV09Zclm7UVtsbPEaiZNqXRDoWCtsKwWJ/SsK2nJMbibRdH1zIgTUoiXyTTJrCenL8S+vpZx24FRteDwtIm/rrJQZqFWQ7XNvRrg5vPiuRPnu3ozLSvwVnAD4MIDTwp6GGNgNAjeBb2ZBs3fK2/jx+gVPqYm82P0o/x/3740yAUDU2hECUxy+NQISYISs3Tbdp89+qRLzpeGO7817WB10mhW4fg8BE0uoKN2kV4R7jS02RYwAl6NgS+h9536vDj/BQlOWVX0BgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var a = '_18lqgzt9';
export var aMegaMenu = '_18lqgztf';
export var aMenu = '_18lqgztc';
export var clink = '_18lqgzt7';
export var container = '_18lqgzt4';
export var divMenu = '_18lqgztb';
export var img = '_18lqgzt8';
export var liMegaMenu = '_18lqgzte';
export var liMenu = '_18lqgzta';
export var links = '_18lqgzt5';
export var logoA = '_18lqgzt6';
export var logoDiv = '_18lqgzt1';
export var menuContainer = '_18lqgzt2';
export var menuWrap = '_18lqgzt3';
export var wrap = '_18lqgzt0';
export var wrapMegaMenu = '_18lqgztd';