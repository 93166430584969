import "src/components/organisms/Headers/OgMegaHeader/index.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/Headers/OgMegaHeader/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE41VwXLbIBS8+yveTKczyQGPJFuOhS+9tL/RQQjZJAg0gGIlHf97RwhFCCtNfbEFj7fL7vpp+zstngvZ9Qn82QBceWUvGNIk+X7aADREn7nEkADprBpWKm5aQd4w1IL1p81ts50apGEDSgR9GLoAgkyz5nHlLAAR/CwRt6wxGCiTlulh+bkzltdviCppmbQYTEsoQyWzV8bkOq8L4+eLxXDQrFnQyhytO+ivMaaOaZK0/QyK9Licb9MYarcONXyjimtGLVcSA1Wia+Q/7h+ZEBBxzyWhL2etOlkhqoTSGL79cp9hs1WGjzCaCWL5KxtW3xGXFesxFEWxoLxfp/wZsQu3DDmlMEh11aRddMtdt1pJiwx/ZxjSwyidW7r6e5RKVItjh/+0aCbiJB3gMYwk1iLRkB55KY+p8zDAfArTmuVO2JZUFZdnDNk2Zw2k2yJ2+OhOTarXdX2K7ptHMIU7MJtCSqNEZ50pVrUY0uOokE/VzmdtikA+PpaqHxAcOS8HKlW/SIcvtay3yPkXCjZR/rnLn5IkZu1BBZcMxf1W0ubvXSpdMY3uW/sN3pAzw8Alt5yIYEOTincGQ5ZMtxuWMRgleAVZ28/Nbhvzeg6tymMjidu9Z1kKQl8CKcfBEA8KANppM9S3ik9irdvlHUo+rEsWPMpliLl0apZCjSysJvKjqxCw3ZuFe6MS68iC1aHBA/ZuId2saOzZ7Nak3xD1kDauuTYW0QsXlbuC659lkcwlFuS+bHeMyqjbC/55+2SZ6CJZ4lehufEw3x/G9j8aVnEChmrGJBBZwUPD5QTxdDi2/aPrs12+zT4dZLewNvW185z10VhU7XzVbJGxnL68ndzqFIjhdzzvtbredSN33cJxHbwCPJWv2JW+3xiVzEfF88qT6XE9LuuB+SCxH6fjEu8uNh5r77Gi6ig8vviwVkx9hX/d+ht9XPr2F0oSnXCyCAAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var aMegaMenu = '_19j9nux8';
export var aMenu = '_19j9nux5';
export var divHeader = '_19j9nux2';
export var h1 = '_19j9nuxc';
export var header = '_19j9nux3';
export var humburgerButton = '_19j9nuxa';
export var img = '_19j9nuxd';
export var liMegaMenu = '_19j9nux7';
export var liMenu = '_19j9nux4';
export var logoDiv = '_19j9nuxe';
export var navCenter = '_19j9nux0';
export var navHeader = '_19j9nux1';
export var quantityDiv = '_19j9nux9';
export var span = '_19j9nuxb';
export var ulMegaMenu = '_19j9nux6';