import React, { FC } from 'react';
import Client from 'shopify-buy';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink } from 'apollo-link';

interface Props {
  client: any;
  apolloClient: any;
  products: any;
  collections: Client.Collection[];
  blogs: any;
  product: any;
  checkout: Client.Cart;
  isCartOpen: any;
  loading: Boolean;
  fetchAllProducts: () => void;
  fetchAllCollections: () => void;
  fetchAllBlogs: () => void;
  removeCheckoutItem: (checkoutId: any, productId: any) => void;
  fetchProductWithId: (id: string) => void;
  addItemToCheckout: (variantId: any, quantity: any) => void;
}

const wordPressLink = createHttpLink({
  uri: `https://admin.cryingtiger.jp/index.php?graphql`,
});

const apolloClient = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'shopify',
    wordPressLink,
  ),

  cache: new InMemoryCache(),
});

const ShopifyContext = React.createContext({} as Props);

const ShopifyProvider: FC = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const ShopifyConsumer = ShopifyContext.Consumer;

export { ShopifyConsumer, ShopifyContext, ShopifyProvider };
