import React from "react"

export default [
    {
        "id": 1,
        "title": "ABOUT",
        "link": "/about",
        "listMenu": [
            {
                "id": 1.1,
                "text": "代表挨拶",
                "link": "/about#greeting",
            },
            {
                "id": 1.2,
                "text": "理念",
                "link": "/about#philosophy",
            },
            {
                "id": 1.3,
                "text": "法人概要",
                "link": "/about#overview",
            },
        ],
    },
    // {
    //     "id": 2,
    //     "title": "ACTIVITY",
    //     "link": "/activity",
    //     "listMenu": [
    //         {
    //             "id": 2.1,
    //             "text": "教育",
    //             "link": "/activity#education",
    //         },
    //         {
    //             "id": 2.2,
    //             "text": "イベント",
    //             "link": "/activity#event",
    //         },
    //         {
    //             "id": 2.3,
    //             "text": "コミュニティ",
    //             "link": "/activity#community",
    //         },
    //     ],
    // },
    {
        "id": 3,
        "title": "EVENT",
        "link": "/event",
        "listMenu": [
            {
                "id": 3.1,
                "text": "全て",
                "link": "/event",
            },
            {
                "id": 3.2,
                "text": "開催前イベント",
                "link": "/event#before",
            },
            {
                "id": 3.3,
                "text": "終了イベント",
                "link": "/event#end",
            },
        ],
    },
    {
        "id": 4,
        "title": "HYPER GRAVITY",
        "link": "/hyper-gravity",
        "listMenu": [
            {
                "id": 4.1,
                "text": "HYPER GRAVITYについて",
                "link": "/hyper-gravity",
            },
        ],
    },
    // {
    //     "id": 4,
    //     "title": "NEWS",
    //     "link": "/blog",
    //     "listMenu": [
    //         {
    //             "id": 4.1,
    //             "text": "お知らせ",
    //             "link": "/blog#information",
    //         },
    //         {
    //             "id": 4.2,
    //             "text": "プレスリリース",
    //             "link": "/blog#release",
    //         },
    //         {
    //             "id": 4.3,
    //             "text": "活動報告",
    //             "link": "/blog#report",
    //         },
    //     ],
    // },
    {
        "id": 5,
        "title": "CONTACT",
        "link": "/contact",
        "listMenu": [
            {
                "id": 5.1,
                "text": "お問い合わせ",
                "link": "/contact",
            },
        ],
    },

]
