import React, { useContext } from 'react';
import 'normalize.css';

import type { GatsbyBrowser } from 'gatsby';
import { RecoilRoot } from 'recoil';
import { RootPrivider } from './src/components/provider/RootProvider/index';

import { GatsbyContext, GatsbyProvider } from './src/context/context';
import { ShopifyProvider } from './src/context/shopifyContext';
import { OgMegaHeader } from './src/components/organisms';
import Footer from './src/components/organisms/WordPress/Footer';
import '@fontsource/noto-sans-jp';
import '@fontsource/bebas-neue';
import '@fontsource/roboto';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return (
    <RecoilRoot>
      <RootPrivider>
        <GatsbyProvider>
          <ShopifyProvider>
            <OgMegaHeader />
            <HelmetProvider>
              <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                  rel="preconnect"
                  href="https://fonts.gstatic.com"
                  crossOrigin="anonymous"
                />
                <link
                  href="https://fonts.googleapis.com/css2?family=Roboto&family=Noto+Sans+JP&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
            </HelmetProvider>
            {element}
            <Footer />
          </ShopifyProvider>
        </GatsbyProvider>
      </RootPrivider>
    </RecoilRoot>
  );
};

export { wrapPageElement };
