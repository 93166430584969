import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyContext } from '../../../../context/context';
import { sprinkles } from '../../../../styles/sprinkles.css';
import {
  humburgerButton,
  span,
  header,
  divHeader,
  liMenu,
  aMenu,
  navHeader,
  img,
  logoDiv,
  ulMegaMenu,
  liMegaMenu,
  aMegaMenu,
  quantityDiv,
  h1,
} from './index.css';
import { useGetWordpressMenuSettings } from '../../../../hooks/useGetWordpressMenuSettings';
import { Navigation } from '../../../../components/organisms/WordPress/Navigation';

export const OgMegaHeader = () => {
  const { isSidebarOpen, showSidebar } = useContext(GatsbyContext);
  const [isShowMegaMenu, setIsShowMegaMenu] = useState(false);
  const { loading: menuLoading, data: menuLists } =
    useGetWordpressMenuSettings('GLOBAL');

  const ulHeader = sprinkles({
    display: {
      mobile: 'none',
      tablet: 'none',
      desktop: 'flex',
    },
    gap: '57',
  });

  if (menuLoading) return <></>;

  const menus = menuLists?.menuItems.edges;

  return (
    <>
      <header className={header}>
        <Navigation />
        {!isSidebarOpen && (
          <>
            <div className={navHeader}>
              <h1 className={h1}>
                <Link to="/" onClick={() => setIsShowMegaMenu(false)}>
                  <img
                    className={img}
                    src="/images/logo.png"
                    alt="一般社団法人CRYINGTIGER JAPAN"
                  />
                </Link>
              </h1>

              <div className={divHeader}>
                <ul className={ulHeader}>
                  {menus?.length > 0 &&
                    menus.map((menu) => (
                      <li
                        key={menu.node.id}
                        className={liMenu}
                        onClick={() => setIsShowMegaMenu(false)}
                      >
                        <Link to={menu.node.url} className={aMenu}>
                          {menu.node.label}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className={humburgerButton} onClick={showSidebar}>
              <span className={span} />
              <span className={span} />
              <span className={span} />
            </div>
          </>
        )}
      </header>
    </>
  );
};
